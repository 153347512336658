
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './PrivateAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/Public/Login/Login';
import DashBoard from './Components/Private/Pages/DashBoard/DashBoard';
import Header from './Components/Private/Header/Header';
import Sidebar from './Components/Private/Sidebar/Sidebar';
import Users from './Components/Private/Pages/Users/Users';
import Session from './Components/Private/Pages/Users/Session';
import Payment from './Components/Private/Pages/Payment/Payment';
import Maintenance from './Components/Private/Pages/Maintenance/Maintenance';
import Profile from './Components/Private/Pages/Profile/Profile';
import NotFound from './Components/Public/NotFound/NotFound';
import ActiveSession from './Components/Private/Pages/ActiveSession/ActiveSession';
import UnpaidSessions from './Components/Private/Pages/UnpaidSessions/UnpaidSessions';
import Pricing from './Components/Private/Pages/Pricing/Pricing';
import UserDetail from './Components/Private/Pages/Users/UserDetail';
import WalletTransaction from './Components/Private/Pages/WalletTransaction/WalletTransaction';
import TransactionDetail from './Components/Private/Pages/WalletTransaction/TransactionDetail';
import ChargingStation from './Components/Private/Pages/ChargingStation/ChargingStation';
import CoPartners from './Components/Private/Pages/CoPartners/CoPartners';
import Sessions from './Components/Private/Pages/Sessions/Sessions';
import Notification from './Components/Private/Pages/Notification/Notification';
import Topup from './Components/Private/Pages/Topup/Topup';
import SubscriptionPlan from './Components/Private/Pages/SubscriptionPlan/SubscriptionPlan';
import Benefits from './Components/Private/Pages/Benefits/Benefits';
import Cimb from './Components/Private/Pages/Cimb/Cimb';
import SystemWallet from './Components/Private/Pages/SystemWallet/SystemWallet';
import SerialNo from './Components/Private/Pages/Cimb/SerialNo';
import { isLogIn, isSupport } from './Config/Apis';
import './App.css';
import StationProfile from './Components/Private/Pages/StationProfile/StationProfile';
import Bonus from './Components/Private/Pages/Bonus/Bonus';
import { dataValue } from './CommonReducer';

// import Announcement from './Components/Private/Pages/Announcement/Announcement';


// export const isLogIn = () => {

//   if (localStorage.getItem("EV_TOKEN")) {
//     return { isToken: true };
//   } else {
//     return { isToken: false };
//   }

// };


function App() {
  const dataval = useSelector((state) => state.dataValue)

  return (
    <div className="App">
      <Router>
        {isLogIn().isToken &&
          <>
            <Header />
            <Sidebar />
          </>
        }


        <Routes>
          <Route path="*" element={<NotFound />} exact />
          <Route exact path='/' element={<PublicRoute />}>
            <Route path="/login" element={<Login />} exact />
            <Route path="/" element={<Login />} exact />
          </Route>
          {/* </Routes> */}
          {/* <main id="main" className="main"> */}
          {/* <Routes> */}
          <Route path="*" element={<NotFound />} exact />
          <Route exact path='/' element={<PrivateRoute />}>
            {dataval?.isSupport ?
              <>
                <Route path="/dashboard" element={<DashBoard />} exact />
                <Route path="/users" element={<Users />} exact />
                <Route path="/sessions" element={<Sessions />} exact />
                <Route path="/sessions/active" element={<ActiveSession />} exact />
                <Route path="/sessions/unpaid" element={<UnpaidSessions />} exact />
                <Route path="/transactions" element={<WalletTransaction />} exact />
                <Route path="/transactions/wallet/:id" element={<TransactionDetail />} exact />
                <Route path="/system-wallet" element={<SystemWallet />} exact />
                <Route path="/users/session/:id" element={<Session />} exact />
                <Route path="/users/:id" element={<UserDetail />} exact />
              </>
              :
              <>
                <Route path="/dashboard" element={<DashBoard />} exact />
                <Route path="/users" element={<Users />} exact />
                <Route path="/sessions" element={<Sessions />} exact />
                <Route path="/sessions/active" element={<ActiveSession />} exact />
                <Route path="/sessions/unpaid" element={<UnpaidSessions />} exact />
                <Route path="/transactions" element={<WalletTransaction />} exact />
                <Route path="/transactions/wallet/:id" element={<TransactionDetail />} exact />
                <Route path="/payment" element={<Payment />} exact />
                <Route path="/maintenance" element={<Maintenance />} exact />
                <Route path="/profile" element={<Profile />} exact />
                <Route path="/users/session/:id" element={<Session />} exact />
                <Route path="/users/:id" element={<UserDetail />} exact />


                <Route path="/co-partners" element={<CoPartners />} exact />
                <Route path="/charging-station" element={<ChargingStation />} exact />
                <Route path="/pricing" element={<Pricing />} exact />
                <Route path="/notification" element={<Notification />} exact />
                <Route path="/topup" element={<Topup />} exact />
                <Route path="/subscription" element={<SubscriptionPlan />} exact />
                <Route path="/subscription/benefits" element={<Benefits />} exact />
                <Route path="/cimb" element={<Cimb />} exact />
                <Route path="/cimb/code" element={<SerialNo />} exact />
                <Route path="/system-wallet" element={<SystemWallet />} exact />
                <Route path="/station-profile" element={<StationProfile />} exact />

                <Route path="/bonus" element={<Bonus />} exact />
              </>
            }

            {/* <Route path="/announcement" element={<Announcement />} exact /> */}
          </Route>
        </Routes>
        {/* </main> */}
      </Router>
    </div>
  );
}

export default App;
